<template>
  <div class="scrollCta" :class="{ 'scrollCta--show': isShow }" ref="cta">
    <button class="scrollCta__button" ref="scrollCtaRef" @click="open">
      <span class="is-hidden">Partager</span>
      <!-- {{ content.label }}-->
      <span class="ScrollCta__icon">
        <svg
          class="ScrollCta__svg"
          width="56"
          height="56"
          xmlns="http://www.w3.org/2000/svg"
        >
          <!-- eslint-disable -->
          <path
            d="M33 24.1v.8h2.5v12.7H21.2V24.9h2.5v-.8h-3.4v14.3h16V24.1H33zM27.9 18v12.4h.8V18l3.6 3.9.6-.5-4.7-5.1-4.7 5.1.6.5 3.8-3.9z"
          />
          <path
            d="M28.3 54.3c-14.3 0-26-11.7-26-26s11.7-26 26-26 26 11.7 26 26c0 14.4-11.6 26-26 26zm0-51c-13.8 0-25 11.2-25 25s11.2 25 25 25 25-11.2 25-25-11.2-25-25-25z"
          />
        </svg>
      </span>
    </button>
    <Share
      :class="{ 'share--show': isOpen }"
      @close="close"
      :content="content"
    />
  </div>
</template>

<style lang="scss" scoped></style>

<script>
import { defineComponent, ref, onMounted } from '@vue/composition-api'

import Share from '@/components/landing/Share.vue'
import gsap from 'gsap'

const DISTANCE = 25

export default defineComponent({
  name: 'ScrollCta',
  props: ['content'],
  components: {
    Share,
  },
  data() {
    return {
      isOpen: false,
      isShow: true,
    }
  },
  methods: {
    open() {
      this.isOpen = true
    },
    close() {
      this.isOpen = false
    },
  },
  setup() {
    const display = ref(false)

    const scrollCtaRef = ref()

    const hide = () => {
      gsap.set(scrollCtaRef.value, {
        autoAlpha: 0,
        y: DISTANCE,
        rotateZ: 0.0001, // Prevent choppy animation
      })
    }

    const animateIn = () => {
      display.value = true

      const TL = gsap.timeline({
        defaults: {
          duration: 2,
          ease: 'power4.out',
        },
      })

      TL.to(
        scrollCtaRef.value,
        {
          autoAlpha: 1,
          y: 0,
          rotateZ: 0.0001, // Prevent choppy animation
          stagger: 0.1,
        },
        '-=1'
      )
    }

    onMounted(() => {
      hide()
      document.addEventListener('desactivateScroll', desactivate)
      document.addEventListener('activateScroll', activate)
    })

    const desactivate = () => {
      hide()
    }

    const activate = () => {
      animateIn()
    }

    return {
      display,
      scrollCtaRef,
      animateIn,
    }
  },
})
</script>
