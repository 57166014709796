import { lerp } from 'math-toolbox'
import bidello, { component } from 'bidello'
import ScrollSection from './ScrollSection'
import { viewport } from './viewport'

const wheelMultiplier = 0.25
const SCROLL_LERP_AMOUNT = 0.1
const SCROLL_LERP_THRESHOLD = 1

const getScrollY = () =>
  window.pageYOffset || document.documentElement.scrollTop

export default class HorizontalScroll extends component() {
  init() {
    this.domEl = this._args[0].root
    this.verticalPlaceholderEl = this._args[0].verticalPlaceholder

    this.viewport = viewport
    this.sections = []
    this.scrollY = 0
    this.translateX = this.scrollY
    this.smoothedTranslateX = this.translateX

    this.render = this.render.bind(this)
    this.renderRAF = window.requestAnimationFrame(this.render)

    this.buildSectionsList()
    this.setup()
  }

  activate() {
    this.addEventListeners()
  }

  desactivate() {
    this.removeEventListeners()
  }

  /**
   * Fetch and store the individuals "scroll section" from the DOM
   * FIXME: Re-run if additionnal sections are added to this.domEl
   */
  buildSectionsList() {
    const sectionsElCollection = this.domEl.querySelectorAll('[data-section]')
    sectionsElCollection.forEach(el => {
      this.sections.push(new ScrollSection(el))
    })
  }

  /**
   * Compute and set the height of the dummy element that creates vertical scrollbar
   */
  setScrollHeight() {
    let scrollHeight = window.innerHeight

    scrollHeight += this.sections.reduce((acc, curr) => acc + curr.BCR.width, 0)

    scrollHeight -= window.innerWidth

    this.verticalPlaceholderEl.style.height = `${scrollHeight}px`
  }

  /**
   *
   */
  setup() {
    this.sections.forEach(section => section.setup())

    this.translateX = this.scrollY
    this.smoothedTranslateX = this.translateX

    this.applyTranslation()
    this.setScrollHeight()
  }

  /**
   * Iterate over sections, update them if they are visible.
   * Also trigger onTranslate event using bidello to keep other components sync
   */
  applyTranslation() {
    const translation = this.smoothedTranslateX * -1

    this.sections.forEach(section => {
      const sectionIsVisible = this.isVisible(section, 0.3)

      if (sectionIsVisible) {
        section.update(translation)
      }
    })

    bidello.trigger(
      { name: 'translate' },
      {
        x: this.smoothedTranslateX,
      }
    )
  }

  /**
   * We are not using IntersectionObserver to check for visibility.
   * A section that is far off the viewport will never trigger the IO
   * since we do not apply transformations on element that dont need it.
   * That's why whe have to manually check for it's visibility by comparing
   * its origin position with current translation
   */
  isVisible(node, tolerance) {
    const offset = this.viewport.width * tolerance
    const docViewLeft = this.smoothedTranslateX
    const docViewRight = docViewLeft + this.viewport.width

    const nodeLeft = node.BCR.left - offset
    const nodeRight = node.BCR.left + (node.BCR.width + offset)

    return nodeLeft < docViewRight && nodeRight > docViewLeft
  }

  render() {
    this.translateX = this.scrollY

    // Performance check to avoid infinite lerping, if smoothed value is close enough to its target
    // Consider that lerping is finished and to not update sections
    if (
      Math.abs(this.translateX - this.smoothedTranslateX) >
      SCROLL_LERP_THRESHOLD
    ) {
      this.smoothedTranslateX = lerp(
        this.smoothedTranslateX,
        this.translateX,
        SCROLL_LERP_AMOUNT
      )
      this.applyTranslation()
    } else {
      this.smoothedTranslateX = this.translateX
    }

    this.renderRAF = requestAnimationFrame(this.render)
  }

  addEventListeners() {
    this.onScroll = this.onScroll.bind(this)
    this.onWheel = this.onWheel.bind(this)

    window.addEventListener('scroll', this.onScroll)
    window.addEventListener('wheel', this.onWheel)
  }

  removeEventListeners() {
    window.removeEventListener('scroll', this.onScroll)
    window.removeEventListener('wheel', this.onWheel)
  }

  onResize() {
    this.setup()
    this.setScrollHeight()
  }

  onScroll() {
    this.scrollY = getScrollY()
  }

  /**
   * Disabling class-methods-use-this because it is nice to have event handlers centralized within the class
   */
  // eslint-disable-next-line class-methods-use-this
  onWheel({ deltaX, wheelDeltaX }) {
    const x = wheelDeltaX || deltaX * -1

    window.scrollBy({
      top: -x * wheelMultiplier,
    })
  }

  destroy() {
    cancelAnimationFrame(this.renderRAF)
    this.removeEventListeners()
  }
}
