<template>
  <!-- eslint-disable vue/no-use-v-if-with-v-for -->
  <div class="slideshow">
    <h2 class="slideshow__title">{{ content.title }}</h2>
    <div class="slideshow__grid">
      <ul class="slideshow__visuals">
        <transition
          v-on:enter="transitionInVisual"
          v-on:leave="transitionOutVisual"
          v-bind:css="false"
        >
          <li
            v-for="(slide, i) in content.slides"
            :key="i"
            class="slideshow__visual"
            v-if="i === slideIndex"
          >
            <img :src="slide.src" alt="" class="slideshow__visual__img" />
          </li>
        </transition>
      </ul>
      <ul class="slideshow__slides">
        <transition
          v-on:enter="transitionInText"
          v-on:leave="transitionOutText"
          v-bind:css="false"
        >
          <li
            v-for="(slide, i) in content.slides"
            :key="i"
            v-if="i === slideIndex"
          >
            <figure class="slideshow__slide">
              <blockquote class="slideshow__slide__content">
                {{ slide.text }}
              </blockquote>
              <figcaption>
                <div class="slideshow__slide__author">
                  {{ slide.author.name }}
                </div>
                <div class="slideshow__slide__author-job">
                  {{ slide.author.job }}
                </div>
              </figcaption>
            </figure>
          </li>
        </transition>
      </ul>
      <div class="slideshow__controls">
        <button class="slideshow__button" @click="prev">
          <svg
            width="54"
            height="54"
            viewBox="0 0 54 54"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <!-- eslint-disable max-len -->
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M19.9141 26.6969L26.9039 19.7071L26.1968 19L18.3534 26.8433L17.9999 27.1969L18.3534 27.5504L26.1968 35.3937L26.9039 34.6866L19.9141 27.6969H37.3877V26.6969H19.9141Z"
              fill="#E49600"
            />
            <rect
              x="0.5"
              y="0.5"
              width="53"
              height="53"
              rx="26.5"
              stroke="#E49600"
            />
          </svg>
        </button>
        <button class="slideshow__button" @click="next">
          <svg
            width="54"
            height="54"
            viewBox="0 0 54 54"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M35.4736 27.6967L28.4838 34.6864L29.1909 35.3936L37.0343 27.5502L37.3878 27.1967L37.0343 26.8431L29.1909 18.9998L28.4838 19.7069L35.4736 26.6967H18V27.6967H35.4736Z"
              fill="#E49600"
            />
            <rect
              x="0.5"
              y="0.5"
              width="53"
              height="53"
              rx="26.5"
              stroke="#E49600"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.slideshow {
  padding: 84px 140px;
  // height: 100%; // Title will stay at the same position, regardless of slide's content height

  @include mq($until: m) {
    padding: 84px 100px;
  }

  @include mq($until: s) {
    padding: 100px 32px;
  }

  &__title {
    font-size: 38px;
    line-height: 1;
    margin-bottom: 41px;
  }

  &__grid {
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 208px minmax(auto, 368px);
    grid-template-rows: 210px 1fr;
    gap: 30px 50px;
    grid-template-areas:
      'visuals text'
      'controls text';

    @include mq($until: s) {
      grid-template-columns: 232px auto;
      grid-template-rows: 210px auto;
      gap: 30px 30px;
      grid-template-areas:
        'visuals controls'
        'text text';
    }
  }

  &__visuals,
  &__slides {
    position: relative;
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  &__visuals {
    grid-area: visuals;
    position: relative;
  }

  &__slides {
    grid-area: text;
  }

  //   &__visual,
  //   &__slide {
  //     position: absolute;
  //     top: 0;
  //     left: 0;
  //   }

  &__visual {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    width: 100%;
    height: 100%;
    overflow: hidden;

    &__img {
      position: relative;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__slide {
    &__content {
      font-family: 'Futura Book';
      font-size: 14px;
      line-height: 2;
      margin-bottom: 36px;
      letter-spacing: 0.6px;

      @include mq($from: s) {
        margin-top: 80px;
      }
    }

    &__author {
      font-family: 'Futura Bold';
      font-size: 14px;
      line-height: 26px;
      letter-spacing: 0.6px;
    }

    &__author-job {
      font-family: 'Futura Book';
      font-size: 13px;
      line-height: 18px;
      color: #7d8093;
      letter-spacing: 0.6px;
    }
  }

  &__controls {
    grid-area: controls;
    display: flex;
    flex-direction: column;

    @include mq($from: s) {
      flex-direction: row;
    }
  }

  &__button {
    width: 54px;
    height: 54px;
    padding: 0;
    border-width: 0;
    background-color: transparent;
    cursor: pointer;

    &:first-of-type {
      margin-bottom: 12px;
    }

    @include mq($from: s) {
      &:first-of-type {
        margin-right: 22px;
        margin-bottom: 0;
      }
    }
  }
}
</style>

<script>
import { defineComponent, ref } from '@vue/composition-api'
import gsap from 'gsap'
import SplitText from '@/inc/vendors/gsap/SplitText'

const TRANSITION_DURATION = 0.5

export default defineComponent({
  name: 'Slideshow',
  props: ['content'],
  setup(props) {
    const slideIndex = ref(0)

    const prev = () => {
      slideIndex.value =
        slideIndex.value >= props.content.slides.length - 1
          ? 0
          : slideIndex.value + 1
    }

    const next = () => {
      slideIndex.value =
        slideIndex.value === 0
          ? props.content.slides.length - 1
          : slideIndex.value - 1
    }

    const transitionInVisual = (el, done) => {
      const target = el.querySelector('img')

      gsap.set(target, {
        zIndex: 1,
      })
      gsap.fromTo(
        target,
        {
          opacity: 0,
          scale: 1.1,
        },
        {
          opacity: 1,
          scale: 1,
          duration: TRANSITION_DURATION,
          ease: 'power3.out',
          onComplete: done,
        }
      )
    }

    const transitionOutVisual = (el, done) => {
      const target = el.querySelector('img')

      gsap.set(target, {
        zIndex: -1,
      })

      gsap.delayedCall(TRANSITION_DURATION, done)
    }

    const transitionInText = (el, done) => {
      const split = new SplitText(el.querySelector('blockquote'), {
        type: 'words',
      })

      const targets = [...split.words, ...el.querySelectorAll('figcaption div')]

      gsap.fromTo(
        targets,
        {
          opacity: 0,
          y: 50,
        },
        {
          opacity: 1,
          y: 0,
          duration: TRANSITION_DURATION,
          ease: 'power2.out',
          stagger: 0.0035,
          onComplete: done,
        }
      )
    }

    const transitionOutText = (el, done) => {
      const target = el.querySelector('figure')

      gsap.set(target, {
        position: 'absolute',
      })

      gsap.to(target, {
        opacity: 0,
        duration: TRANSITION_DURATION,
        ease: 'power3.out',
        onComplete: done,
      })
    }

    return {
      slideIndex,
      prev,
      next,
      transitionInVisual,
      transitionOutVisual,
      transitionInText,
      transitionOutText,
    }
  },
})
</script>
