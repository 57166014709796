import gsap from 'gsap'

export const defaultTimeLine = () =>
  gsap.timeline({
    paused: true,
    delay: 0.25,
    defaults: { duration: 3, ease: 'power4.out' },
  })

export const prepareFadeInUpTween = targets => {
  gsap.set(targets, {
    opacity: 0,
    y: 40,
  })
}

export const fadeInUpTween = targets =>
  gsap.to(targets, {
    opacity: 1,
    y: 0,
    stagger: 0.2,
  })
