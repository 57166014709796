import { render, staticRenderFns } from "./FullscreenMedia.vue?vue&type=template&id=05598db8&scoped=true&"
import script from "./FullscreenMedia.vue?vue&type=script&lang=js&"
export * from "./FullscreenMedia.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05598db8",
  null
  
)

export default component.exports