import bidello, { component } from 'bidello'

class Viewport extends component() {
  init() {
    if (typeof window === 'undefined') {
      return
    }

    this.width = this.getWidth()
    this.height = this.getHeight()
    this.x = 0

    // TODO: Debounce
    window.addEventListener('resize', this.resize)
  }

  // Storing function within class to prevent SSR issues
  // eslint-disable-next-line class-methods-use-this
  getWidth() {
    return window.innerWidth
  }

  // Storing function within class to prevent SSR issues
  // eslint-disable-next-line class-methods-use-this
  getHeight() {
    return window.innerHeight
  }

  onTranslate({ x }) {
    this.x = x
  }

  // I would have named it onResize but since this class is a Bidello component,
  // it would create an infinite loop!
  resize() {
    this.width = window.innerWidth
    this.height = window.innerHeight

    bidello.trigger(
      { name: 'resize', fireAtStart: true },
      {
        width: this.width,
        height: this.height,
      }
    )
  }
}

export const viewport = new Viewport()
