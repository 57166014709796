

















import { defineComponent, onMounted, ref } from '@vue/composition-api'

import {
  defaultTimeLine,
  prepareFadeInUpTween,
  fadeInUpTween,
} from '@/inc/utils/animations'
import ParallaxVisual from '@/components/landing/ParallaxVisual.vue'

export default defineComponent({
  name: 'imageBackground',
  props: ['content'],
  components: { ParallaxVisual },
  setup() {
    const visualRef = ref()

    const animationsTargets = []
    const TL = defaultTimeLine()

    onMounted(() => {
      prepareFadeInUpTween(animationsTargets)
    })

    const enterViewport = () =>
      TL.add(fadeInUpTween(animationsTargets), 'start')
        .add(visualRef.value.animateIn, '<.35')
        .resume()

    return {
      enterViewport,
      visualRef,
    }
  },
})
