import { isLargeViewport } from './horizontalScroll/isLargeViewport'

export default class DragToScroll {
  constructor(options) {
    this.domEl = options.domEl
    this.onDragCallBack = options.onDrag

    this.touchOptions = { passive: false }

    this.isTouching = false

    this.bindEvents()
  }

  activate() {
    this.addEventListeners()
  }

  desactivate() {
    this.removeEventListeners()
  }

  bindEvents() {
    this.onStart = this.onStart.bind(this)
    this.onMove = this.onMove.bind(this)
    this.onEnd = this.onEnd.bind(this)
  }

  addEventListeners() {
    this.domEl.addEventListener('touchstart', this.onStart, this.touchOptions)
    this.domEl.addEventListener('touchmove', this.onMove, this.touchOptions)
    this.domEl.addEventListener('touchend', this.onEnd, this.touchOptions)
    this.domEl.addEventListener('touchcancel', this.onEnd, this.touchOptions)

    this.domEl.addEventListener('mousedown', this.onStart)
    this.domEl.addEventListener('mousemove', this.onMove)
    this.domEl.addEventListener('mouseup', this.onEnd)
    this.domEl.addEventListener('mouseleave', this.onEnd)
    this.domEl.addEventListener('contextmenu', this.onEnd)
  }

  removeEventListeners() {
    this.domEl.removeEventListener(
      'touchstart',
      this.onStart,
      this.touchOptions
    )
    this.domEl.removeEventListener('touchmove', this.onMove, this.touchOptions)
    this.domEl.removeEventListener('touchend', this.onEnd, this.touchOptions)
    this.domEl.removeEventListener('touchcancel', this.onEnd, this.touchOptions)

    this.domEl.removeEventListener('mousedown', this.onStart)
    this.domEl.removeEventListener('mousemove', this.onMove)
    this.domEl.removeEventListener('mouseup', this.onEnd)
    this.domEl.removeEventListener('mouseleave', this.onEnd)
    this.domEl.removeEventListener('contextmenu', this.onEnd)
  }

  onStart() {
    this.isTouching = true
  }

  onEnd() {
    this.isTouching = false
  }

  onMove(e) {
    if (isLargeViewport() && this.isTouching) {
      this.onDragCallBack(e.movementX)
    }
  }
}
