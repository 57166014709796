<template>
  <div
    class="statistic"
    data-parallax
    v-enter-viewport
    @enterViewport="enterViewport"
  >
    <ParallaxVisual
      v-if="content.picture"
      ref="visualRef"
      name="statistic__visual"
      :src="content.picture.src"
      :alt="content.picture.alt"
    />
    <div class="statistic__illustration" aria-hidden="true" ref="illuRef"></div>
    <div class="statistic__content-wrapper">
      <div class="statistic__number" ref="numberRef">
        {{ content.value }}
      </div>
      <div class="statistic__container">
        <h3 class="statistic__title" ref="titleRef">
          {{ content.title }}
        </h3>
        <p class="statistic__txt" ref="textRef" v-if="content.txt">
          {{ content.txt }}
        </p>
        <div class="statistic__links" v-if="content.links" ref="linksRef">
          <a
            :href="link.src"
            class="statistic__link"
            v-for="(link, i) in content.links"
            :key="i"
            target="_blank"
            rel="noopener"
          >
            {{ link.label }}
          </a>
        </div>
        <ReadMore
          ref="linkRef"
          v-if="content.link"
          :label="content.link.label"
          :href="content.link.href"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>

<script>
import { defineComponent, onMounted, ref } from '@vue/composition-api'

import {
  defaultTimeLine,
  prepareFadeInUpTween,
  fadeInUpTween,
} from '@/inc/utils/animations'
import ReadMore from '@/components/landing/ReadMore.vue'
import ParallaxVisual from '@/components/landing/ParallaxVisual.vue'

export default defineComponent({
  name: 'Statistic',
  props: ['content'],
  components: { ReadMore, ParallaxVisual },
  setup() {
    const numberRef = ref()
    const titleRef = ref()
    const textRef = ref()
    const linkRef = ref()
    const linksRef = ref()
    const illuRef = ref()
    const visualRef = ref()
    const animationsTargets = []
    const TL = defaultTimeLine()

    onMounted(() => {
      animationsTargets.push(
        numberRef.value,
        titleRef.value,
        textRef.value,
        linksRef.value,
        linkRef.value.$el,
        illuRef.value
      )
      prepareFadeInUpTween(animationsTargets)
    })

    const enterViewport = () => {
      TL.add(fadeInUpTween(animationsTargets), 'start')
      if (visualRef.value) {
        TL.add(visualRef.value.animateIn, '<.35')
      }
      TL.resume()
    }

    return {
      enterViewport,
      numberRef,
      titleRef,
      textRef,
      linkRef,
      linksRef,
      illuRef,
      visualRef,
    }
  },
})
</script>
