







































































































































/* eslint-disable capitalized-comments */
import { defineComponent, ref, onMounted } from '@vue/composition-api'

import content from '@/inc/data/content.json'
// import { useGetters } from '@u3u/vue-hooks'

// import GlobalHeader from '@/components/GlobalHeader.vue'
import Intro from '@/components/landing/Intro.vue'
import Hero from '@/components/landing/Hero.vue'
import ScrollCta from '@/components/landing/ScrollCta.vue'
import ProgressBar from '@/components/landing/ProgressBar.vue'
import Statistic from '@/components/landing/Statistic.vue'
import Testimonial from '@/components/landing/Testimonial.vue'
import FullscreenMedia from '@/components/landing/FullscreenMedia.vue'
import ImageBackground from '@/components/landing/ImageBackground.vue'
import Signature from '@/components/landing/Signature.vue'
import Action from '@/components/landing/Action.vue'
import Champions from '@/components/landing/Champions.vue'
import Footer from '@/components/landing/Footer.vue'
import PlayCursor from '@/components/g/PlayCursor.vue'
import Slideshow from '@/components/landing/Slideshow.vue'

import HorizontalScroller from '@/components/landing/HorizontalScroller.vue'

export default defineComponent({
  name: 'landing',
  components: {
    // GlobalHeader,
    Intro,
    Hero,
    ScrollCta,
    ProgressBar,
    Statistic,
    Testimonial,
    FullscreenMedia,
    ImageBackground,
    Signature,
    Action,
    Champions,
    Footer,
    PlayCursor,
    HorizontalScroller,
    Slideshow,
  },
  setup() {
    // const { content } = useGetters(['content'])
    const showIntro = ref(true)
    const introRef = ref()
    const heroRef = ref()
    const scrollCtaRef = ref()
    const scrollerRef = ref()

    // Prevent scroll during intro
    onMounted(() => {
      document.body.style.overflowY = 'hidden'
    })

    const onIntroComplete = () => {
      // Let user scroll freely
      scrollerRef.value.activate()
      document.body.style.overflowY = 'auto'

      // Remove intro component from DOM
      showIntro.value = false

      // Animate in first section (hero)
      heroRef.value.animateIn()
      scrollCtaRef.value.animateIn()
    }

    return {
      content,
      showIntro,
      introRef,
      heroRef,
      scrollCtaRef,
      scrollerRef,
      onIntroComplete,
    }
  },
})
