
































import {
  defineComponent,
  onMounted,
  ref,
  onBeforeUnmount,
} from '@vue/composition-api'
import HorizontalScroll from '@/inc/utils/horizontalScroll'
import DragToScroll from '@/inc/utils/DragToScroll'

export default defineComponent({
  name: 'horizontalScroller',
  setup() {
    const rootRef = ref()
    const draggableBoundsRef = ref()
    const verticalPlaceholder = ref()
    const debugRef = ref()

    let horizontalScroll, dragToScroll

    onMounted(() => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      horizontalScroll = new HorizontalScroll({
        root: rootRef.value,
        draggableBounds: draggableBoundsRef.value,
        verticalPlaceholder: verticalPlaceholder.value,
      })

      dragToScroll = new DragToScroll({
        domEl: rootRef.value,
        onDrag: x => {
          window.scrollBy({
            top: -x,
          })
        },
      })

      document.addEventListener('activateScroll', activate)
      document.addEventListener('desactivateScroll', desactivate)
    })

    onBeforeUnmount(() => {
      dragToScroll.removeEventsListeners()
      horizontalScroll.destroy()
    })

    const activate = () => {
      horizontalScroll.activate()
      dragToScroll.activate()
    }

    const desactivate = () => {
      horizontalScroll.desactivate()
      dragToScroll.desactivate()
    }

    return {
      draggableBoundsRef,
      rootRef,
      debugRef,
      verticalPlaceholder,
      activate,
    }
  },
})
