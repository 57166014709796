<template>
  <div
    class="champions"
    data-parallax
    v-enter-viewport
    @enterViewport="enterViewport"
  >
    <div class="champions__content-wrapper">
      <div class="champions__number" ref="numberRef">
        {{ content.value }}
      </div>
      <div class="champions__container">
        <h3 class="champions__title" ref="titleRef">
          {{ content.title }}
        </h3>
        <ReadMore
          ref="linkRef"
          v-if="content.link.label && content.link.href"
          :label="content.link.label"
          :href="content.link.href"
        />
      </div>
    </div>
    <div class="champions__list">
      <div class="champion" v-for="(picture, i) in content.pictures" :key="i">
        <ParallaxVisual
          ref="visualRef"
          name="champions__visual"
          :src="picture.src"
          :alt="picture.alt"
        />
        <p class="champion__title" v-if="picture.title">{{ picture.title }}</p>
        <p class="champion__subtitle" v-if="picture.subtitle">
          {{ picture.subtitle }}
        </p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>

<script>
import { defineComponent, onMounted, ref } from '@vue/composition-api'

import {
  defaultTimeLine,
  prepareFadeInUpTween,
  fadeInUpTween,
} from '@/inc/utils/animations'
import ReadMore from '@/components/landing/ReadMore.vue'
import ParallaxVisual from '@/components/landing/ParallaxVisual.vue'

export default defineComponent({
  name: 'Champions',
  props: ['content'],
  components: { ReadMore, ParallaxVisual },
  setup() {
    const numberRef = ref()
    const titleRef = ref()
    const linkRef = ref()
    const visualRef = ref()
    const animationsTargets = []
    const TL = defaultTimeLine()

    onMounted(() => {
      animationsTargets.push(numberRef.value, titleRef.value, linkRef.value.$el)
      prepareFadeInUpTween(animationsTargets)
    })

    const enterViewport = () => {
      const anims = visualRef.value.map(image => image.animateIn)
      TL.add(fadeInUpTween(animationsTargets), 'start')
      anims.forEach(anim => TL.add(anim, '<.20'))
      TL.resume()
    }

    return {
      enterViewport,
      numberRef,
      titleRef,
      linkRef,
      visualRef,
    }
  },
})
</script>
