import { map } from 'math-toolbox'
import { viewport } from './viewport'

export class ParallaxItem {
  constructor(domEl) {
    this.domEl = domEl
    this.width = 0
    this.left = 0

    this.setDimensions()
  }

  setDimensions() {
    const BCR = this.domEl.getBoundingClientRect()
    this.width = BCR.width
    this.halfWidth = this.width / 2
    this.left = BCR.left
  }

  update() {
    // Center of node is aligned with right edge of viewport,
    const min = this.left - viewport.width + this.halfWidth

    // Center of node aligned with left edge of viewport
    const max = this.left + this.halfWidth

    const progress = map(viewport.x, min, max, -1, 1)

    this.domEl.style.setProperty('--progress', progress)
  }
}
