<template>
  <div
    class="action"
    data-parallax
    v-enter-viewport
    @enterViewport="enterViewport"
  >
    <h2 class="action__title" ref="titleRef">{{ content.title }}</h2>
    <div class="action__container">
      <div class="action__left">
        <p class="action__txt" ref="textRef">
          {{ content.txt1 }}
        </p>
        <p class="action__txt" ref="textRef">
          {{ content.txt2 }}
        </p>
        <ParallaxVisual
          v-if="content.picture"
          ref="visualRef"
          name="action__visual"
          :src="content.picture.src"
          :alt="content.picture.alt"
        />
      </div>
      <div class="action__right" ref="contentRef">
        <div
          class="action__content"
          v-for="(item, i) in content.items"
          :key="i"
        >
          <h3 class="action__subtitle">
            {{ item.subtitle }}
          </h3>
          <div class="action__links">
            <div
              class="action__link-wrapper"
              v-for="(link, i) in item.links"
              :key="i"
            >
              <a
                :href="link.href"
                class="action__link"
                target="_blank"
                rel="noopener"
              >
                {{ link.label }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>

<script>
import { defineComponent, onMounted, ref } from '@vue/composition-api'

import {
  defaultTimeLine,
  prepareFadeInUpTween,
  fadeInUpTween,
} from '@/inc/utils/animations'
import ParallaxVisual from '@/components/landing/ParallaxVisual.vue'

export default defineComponent({
  name: 'Action',
  props: ['content'],
  components: { ParallaxVisual },
  setup() {
    const titleRef = ref()
    const textRef = ref()
    const contentRef = ref()
    const visualRef = ref()
    const animationsTargets = []
    const TL = defaultTimeLine()

    onMounted(() => {
      animationsTargets.push(titleRef.value, textRef.value, contentRef.value)
      prepareFadeInUpTween(animationsTargets)
    })

    const enterViewport = () =>
      TL.add(fadeInUpTween(animationsTargets), 'start')
        .add(visualRef.value.animateIn, '<.35')
        .resume()

    return {
      enterViewport,
      titleRef,
      textRef,
      contentRef,
      visualRef,
    }
  },
})
</script>
