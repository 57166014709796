<template>
  <div :class="wrapperClass" ref="rootRef">
    <slot></slot>
    <img :src="src" :alt="alt" :class="visualClass" />
  </div>
</template>

<style lang="scss" scoped>
$parallax-amount: 10%;

.wrapper {
  position: relative;
  overflow: hidden;
}

.visual {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: none;
  height: 100%;
  width: 100%;
  object-fit: cover;

  @include mq($from: m) {
    @include parallax-translate-x(-$parallax-amount);

    right: -$parallax-amount * 2;
    left: -$parallax-amount * 2;
    width: 100 + $parallax-amount * 4;
  }
}
</style>

<script>
import { defineComponent, computed, ref, onMounted } from '@vue/composition-api'
import gsap from 'gsap'

export default defineComponent({
  name: 'parallaxVisual',
  props: {
    name: {
      type: String,
      required: true,
    },
    src: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup(props) {
    const rootRef = ref()
    const wrapperClass = computed(() => `${props.name}-wrapper wrapper`)
    const visualClass = computed(() => `${props.name} visual`)

    onMounted(() => {
      gsap.set(rootRef.value, {
        clipPath: 'inset(0% 0% 0% 100%)',
      })
    })

    const animateIn = () =>
      gsap.to(rootRef.value, {
        clipPath: 'inset(0% 0% 0% 0%)',
      })

    return { wrapperClass, visualClass, rootRef, animateIn }
  },
})
</script>
