<template>
  <div
    class="signature"
    data-parallax
    v-enter-viewport
    @enterViewport="enterViewport"
  >
    <div class="signature__container">
      <h2 class="signature__title" v-if="content.title" ref="titleRef">
        {{ content.title }}
      </h2>
      <p class="signature__txt" v-if="content.txt" ref="contentRef">
        {{ content.txt }}
      </p>
      <ReadMore
        ref="linkRef"
        v-if="content.link.label && content.link.href"
        :label="content.link.label"
        :href="content.link.href"
      />
      <div class="signature__signature" :class="classes">
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 515.9 221.1"
          style="enable-background: new 0 0 515.9 221.1"
          xml:space="preserve"
          class="signature__signature"
          ref="svg"
        >
          <path
            d="M4.2,133.2c51-49.6,109.4-103.3,118.4-95.6c3.8,3.2-2.3,15.9-9.1,30C73.2,150.9,38.1,215.9,39.6,217
    c0.8,0.6,56.1-101.5,154.5-207.5c2-2.2,3.7-4,5-5.4"
          />
          <path
            d="M173.1,112.3C135.8,159,125,175.6,127,177.4c2.6,2.4,27.4-22,31.9-18.1c3.6,3.1-9.3,20.8-6.2,23.4
    c5.3,4.5,50.9-40.6,52.2-39.3c0.8,0.8-14.9,16.6-13.6,18c0.7,0.7,5.7-2.3,17.5-11.4"
          />
          <path
            d="M292.3,61.3c-70.1,96-85.3,126.5-80.2,130.9c5.7,5,37.3-22.9,71.7-57c-0.8-0.1-1.3-0.3-1.6-0.7
    c-7.4-9.4,97.9-107.9,99.8-106c0.2,0.3-1.6,2.6-4.7,6.4c-14,17.3-55.4,62.6-93.5,100.3c4.4,0.4,16.5-4.9,40.9-19.6"
          />
          <path
            d="M313.1,150.8c-8.4,19.7-8.4,24.6-6.9,25.5c4.7,2.9,29.5-29.2,48.4-53.4c27.6-35.5,50.2-67,50.5-66.8
            c0.4,0.3-35.4,53.3-47.1,62.5c-0.5,0.4-0.5,3.7,0,5.3c0.8,2.7,9.6,0.1,11.6,3.2c2.8,4.3-11.5,12.9-15.2,29.1
            c-2.6,11.2,0.3,24.1,5.4,25.5c4.7,1.4,7.9-7.7,18.2-10.9c10.1-3.1,19.8,1.7,23,3.1c12.4,5.3,41.4,7.6,110.7-2.9"
          />
        </svg>
      </div>
    </div>
    <ParallaxVisual
      v-if="content.picture"
      ref="visualRef"
      name="signature__visual"
      :src="content.picture.src"
      :alt="content.picture.alt"
    />
  </div>
</template>

<style lang="scss" scoped></style>

<script>
import { defineComponent, onMounted, ref } from '@vue/composition-api'
import {
  defaultTimeLine,
  prepareFadeInUpTween,
  fadeInUpTween,
} from '@/inc/utils/animations'
import ReadMore from '@/components/landing/ReadMore.vue'
import ParallaxVisual from '@/components/landing/ParallaxVisual.vue'

export default defineComponent({
  name: 'Signature',
  props: ['content'],
  components: {
    ReadMore,
    ParallaxVisual,
  },
  mounted() {
    const paths = this.$refs.svg.querySelectorAll('path')
    let length, i
    for (i = 0; i < paths.length; i++) {
      length = paths[i].getTotalLength()
      paths[i].setAttribute(
        'style',
        `stroke-dashoffset:${length}px;stroke-dasharray:${length}px`
      )
    }
  },
  computed: {
    classes() {
      return {
        'signature__signature--visible': this.display,
      }
    },
  },
  setup() {
    const titleRef = ref()
    const linkRef = ref()
    const svg = ref()
    const contentRef = ref()
    const visualRef = ref()

    const animationsTargets = []
    const TL = defaultTimeLine()

    onMounted(() => {
      animationsTargets.push(
        titleRef.value,
        contentRef.value,
        linkRef.value.$el
      )
      prepareFadeInUpTween(animationsTargets)
    })

    const enterViewport = () => {
      const paths = svg.value.querySelectorAll('path')
      let i
      for (i = 0; i < paths.length; i++) {
        paths[i].style.strokeDashoffset = '0px'
      }
      TL.add(fadeInUpTween(animationsTargets), 'start')
        .add(visualRef.value.animateIn, '<.35')
        .resume()
    }

    return {
      enterViewport,
      titleRef,
      linkRef,
      svg,
      contentRef,
      visualRef,
    }
  },
})
</script>
