<template>
  <div class="intro">
    <div class="intro__logo-container">
      <picture class="intro__logo" ref="logoRef">
        <source
          type="image/png"
          srcset="
            @/assets/img/logo/dark/logo-dark@2x.png 2x,
            @/assets/img/logo/dark/logo-dark.png    1x
          "
        />
        <img
          src="@/assets/img/logo/dark/logo-dark.png"
          alt="Food Forever Initiative's logo"
        />
      </picture>
    </div>
    <div class="intro__content">
      <div class="intro__text" ref="textRef">
        <p>
          The Food Forever Initiative was a three-year time-bound awareness
          raising campaign culminating in 2020, the target year for reaching
          United Nations Sustainable Development Target 2.5. While there is
          still much work to be done globally to reach the target, the Food
          Forever Initiative reached hundreds of thousands of individuals and
          put crop diversity higher on the global development agenda.
        </p>
      </div>
      <button class="intro__skip" @click="skipIt" ref="skipRef">
        Skip intro
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>

<script>
import { defineComponent, ref, onMounted } from '@vue/composition-api'
import gsap from 'gsap'
import SplitText from '@/inc/vendors/gsap/SplitText'

const DISTANCE = 25
const TEXT_DURATION = 10 // 20

export default defineComponent({
  name: 'intro',
  emit: ['onComplete'],
  setup(props, ctx) {
    const logoRef = ref()
    const textRef = ref()
    const skipRef = ref()
    const words = ref([])

    const TL = gsap.timeline({
      paused: true,
      delay: 0.25,
      onComplete: () => {
        ctx.emit('onComplete')
      },
      defaults: {
        duration: 2,
        ease: 'power4.out',
      },
    })

    function splitIt() {
      const split = new SplitText(textRef.value, {
        type: 'words',
      })
      words.value.push(...split.words)
    }

    function hideIt() {
      gsap.set([skipRef.value, logoRef.value, words.value], {
        autoAlpha: 0,
        y: DISTANCE,
        rotateZ: 0.0001, // Prevent choppy animation
      })
    }

    function animateIt() {
      TL.addLabel('logoIn')
        .to(
          logoRef.value,
          {
            autoAlpha: 1,
            y: 0,
            duration: 1,
          },
          'logoIn'
        )
        .addLabel('logoIn')
        .to(
          logoRef.value,
          {
            y: -190,
            duration: 2,
          },
          'textIn'
        )
        .to(
          [words.value],
          {
            autoAlpha: 1,
            y: 0,
            delay: 0.8,
            stagger: 0.01,
          },
          'textIn'
        )
        .to(
          skipRef.value,
          {
            autoAlpha: 1,
            y: 0,
            delay: 1.5,
          },
          'textIn'
        )
        .addLabel('textOut', `+=${TEXT_DURATION}`)
        .to(
          [words.value],
          {
            autoAlpha: 0,
            y: -DISTANCE,
            stagger: 0.01,
          },
          'textOut'
        )
        .to(
          skipRef.value,
          {
            autoAlpha: 0,
            y: DISTANCE,
            ease: 'power4.out',
            duration: 1,
          },
          'textOut'
        )
        .to(
          logoRef.value,
          {
            autoAlpha: 0,
          },
          'textOut'
        )

      TL.play()
    }

    function skipIt() {
      const currentLabel = TL.currentLabel()
      TL.kill()

      const exitTL = gsap.timeline({
        defaults: {
          duration: 1,
          ease: 'power4.out',
        },
        onComplete: () => {
          ctx.emit('onComplete')
        },
      })

      exitTL.to(
        [words.value],
        {
          autoAlpha: 0,
          y: -DISTANCE,
          stagger: 0.01,
        },
        0
      )

      exitTL.to(
        skipRef.value,
        {
          autoAlpha: 0,
          y: DISTANCE,
          ease: 'power4.out',
          duration: 1,
        },
        0
      )

      switch (currentLabel) {
        case 'textIn':
        case 'textOut':
        default:
          exitTL.to(
            [words.value],
            {
              autoAlpha: 0,
              y: -DISTANCE,
              stagger: 0.01,
            },
            0
          )
          break
        case 'logoIn':
        case 'logoOut':
          exitTL.to(
            logoRef.value,
            {
              autoAlpha: 0,
              ease: 'power4.out',
              duration: 1,
            },
            0
          )
          break
      }
    }

    onMounted(() => {
      splitIt()
      hideIt()
      animateIt()
    })

    return {
      logoRef,
      textRef,
      skipRef,
      skipIt,
    }
  },
})
</script>
