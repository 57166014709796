import { ParallaxItem } from './ParallaxItem'

export default class ScrollSection {
  constructor(el) {
    this.domEl = el
    this.parallaxItems = []
  }

  setup() {
    this.getDimensions()
    this.domEl.querySelectorAll('[data-parallax]').forEach(item => {
      this.parallaxItems.push(new ParallaxItem(item))
    })
  }

  update(translation) {
    this.domEl.style.transform = `translate3d(${translation}px, 0 , 0)`

    this.parallaxItems.forEach(parallaxItem => {
      parallaxItem.update()
    })
  }

  getDimensions() {
    this.BCR = this.domEl.getBoundingClientRect()
  }
}
