<template>
  <footer class="footer">
    <h2 class="is-hidden">{{ content.title }}</h2>
    <div class="footer__illustration" aria-hidden="true"></div>
    <div class="footer__content">
      <picture class="footer__logo" ref="logoRef">
        <source
          type="image/png"
          srcset="@/assets/img/logo/logo-dark-left.png 1x"
        />
        <img
          src="@/assets/img/logo/logo-dark-left.png"
          alt="Food Forever Initiative's logo"
        />
      </picture>

      <g-wysiwyg :wysiwyg="content.txt" class="footer__txt" />

      <g-wysiwyg :wysiwyg="content.socials_intro" />
      <Socials :content="content.socials" />
    </div>
    <div class="footer__footer">
      <picture class="footer__croptrust" ref="logoRef">
        <source type="image/png" srcset="@/assets/img/crop-trust.png 1x" />
        <img
          src="@/assets/img/crop-trust.png"
          alt="Food Forever Initiative's logo"
        />
      </picture>
      <a
        href="https://www.croptrust.org/news-center/"
        class="newscenter link"
        target="_blank"
        >Stay involved</a
      >
    </div>
  </footer>
</template>

<style lang="scss" scoped>
.form-feedback {
  margin-top: 1em;
  font-size: 1.4rem;
}

.newscenter {
  margin-top: 30px;
}
</style>

<script>
import { defineComponent, ref } from '@vue/composition-api'
import Socials from '@/components/landing/Socials.vue'

export default defineComponent({
  name: 'Footer',
  props: ['content'],
  components: { Socials },
  setup() {
    const feedback = ref('')
    const email = ref('')
    const url = 'https://www.food4ever.org/wp/wp-admin/admin-ajax.php'
    const onSubmit = () => {
      const data = new FormData()
      data.append('email', email.value)
      data.append('action', 'newsletter_form')

      fetch(url, {
        method: 'post',
        body: data,
      }).then(response => {
        if (response.status === 200) {
          response.json().then(data => {
            feedback.value = data.message
          })
        }
      })
    }

    return { email, onSubmit, feedback }
  },
})
</script>
