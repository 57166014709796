<template>
  <div class="socials">
    <a
      :href="content.linkedin"
      class="socials__link socials__link--linkedin"
      target="_blank"
    >
      <span class="is-hidden">Linkedin</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 56.7 56.7"
        xml:space="preserve"
        class="socials__svg"
        fill="#2A67BC"
      >
        <path
          d="M49.7 49.8V34.1c0-7.7-1.7-13.6-10.6-13.6-4.3
        0-7.2 2.4-8.4 4.6h-.1v-3.9h-8.5v28.6H31V35.6c0-3.7.7-7.3 5.3-7.3
        4.5 0 4.6 4.3 4.6 7.6v13.9h8.8zM7.6
        21.2h8.9v28.6H7.6V21.2zM12.1 6.9C9.3 6.9 7 9.2 7
        12s2.3 5.2 5.1 5.2 5.1-2.4 5.1-5.2-2.3-5.1-5.1-5.1z"
        />
      </svg>
    </a>
    <a
      :href="content.facebook"
      class="socials__link socials__link--facebook"
      target="_blank"
    >
      <span class="is-hidden">Facebook</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 56.7 56.7"
        class="socials__svg"
        fill="#3579EA"
      >
        <path
          d="M36.1 12h4.5V4.3c-.8-.1-3.4-.3-6.5-.3-6.4
        0-10.8 4-10.8 11.5v6.8h-7.1V31h7.1v21.8H32V30.9h6.8l1.1-8.7H32v-6c-.1-2.4.6-4.2
        4.1-4.2z"
        />
      </svg>
    </a>
    <a
      :href="content.twitter"
      class="socials__link socials__link--twitter"
      target="_blank"
    >
      <span class="is-hidden">Twitter</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 56.7 56.7"
        class="socials__svg"
        fill="#4AA1EB"
      >
        <path
          d="M52.9 13.1c-1.8.8-3.8 1.3-5.8 1.6 2.1-1.2
        3.7-3.2 4.4-5.6-1.9 1.2-4.1 2-6.4 2.4-1.9-2-4.5-3.2-7.4-3.2-5.6
        0-10.1 4.5-10.1 10.1 0 .8.1 1.6.2 2.3C19.4 20.3 12 16.3 7
        10.1c-.9 1.5-1.4 3.2-1.4 5.1 0 3.5 1.8 6.6 4.5 8.4-1.6
        0-3.2-.5-4.6-1.2v.1c0 4.9 3.5 9 8.1 9.9-.8.2-1.7.3-2.6.3-.6
        0-1.3 0-1.9-.2 1.3 4 5 6.9 9.4 7-3.4 2.7-7.8 4.3-12.5
        4.3-.8 0-1.6 0-2.4-.1 4.5 2.9 9.8 4.5 15.5 4.5 18.6 0
        28.7-15.4 28.7-28.7v-1.3c2.1-1.3 3.8-3.1 5.1-5.1z"
        />
      </svg>
    </a>
  </div>
</template>

<style lang="scss" scoped></style>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'Socials',
  props: ['content'],
})
</script>
