<template>
  <div
    class="fullscreen-media"
    data-parallax
    v-enter-viewport
    @enterViewport="enterViewport"
  >
    <button
      class="fullscreen-media__play"
      aria-hidden="true"
      @click="showVideo"
      v-show="display"
      v-on:mouseover="mouseover"
      v-on:mouseleave="mouseleave"
    >
      <span class="is-hidden">play</span>
    </button>
    <div class="fullscreen-media__responsive">
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0"
        y="0"
        viewBox="0 0 124.7 124.7"
        xml:space="preserve"
      >
        <path d="M69.8 65.1l-11.1 6.4V58.6l11.1 6.5z" />
        <path
          class="st0"
          d="M62.4 122.4c-33.1 0-60-26.9-60-60s26.9-60
        60-60 60 26.9 60 60-27 60-60 60zm0-119c-32.5
        0-59 26.5-59 59s26.5 59 59 59 59-26.5
        59-59c0-32.6-26.5-59-59-59z"
        />
      </svg>
    </div>
    <!-- @TODO CHECK HEADINGS LEVELS -->
    <div class="fullscreen-media__content">
      <h2
        class="fullscreen-media__pre-pretitle"
        v-if="content.pretitle"
        ref="preTitleRef"
      >
        {{ content.pretitle }}
      </h2>
      <div
        class="fullscreen-media__title"
        v-if="content.title.secondLine"
        ref="title2Ref"
      >
        {{ content.title.secondLine }}
      </div>
      <ReadMore
        ref="linkRef"
        v-if="content.link"
        :label="content.link.label"
        :href="content.link.href"
        id="whitelink"
      />
    </div>
    <div class="fullscreen-media__background" v-if="content.picture">
      <div class="fullscreen-media__visual-container" data-parallax>
        <img
          class="fullscreen-media__visual"
          :src="content.picture.src"
          :alt="content.picture.alt"
          ref="visualRef"
        />
      </div>
      <div class="fullscreen-media__shadow-overlay"></div>
    </div>
    <VideoPlayer
      :sources="[
        { src: '/assets/video/food-forever-experience.mp4', type: 'video/mp4' },
      ]"
      :muted="false"
      ref="player"
    />
  </div>
</template>

<style lang="scss" scoped></style>

<script>
import { defineComponent, onMounted, ref } from '@vue/composition-api'
import VideoPlayer from '@/components/landing/VideoPlayer.vue'
import ReadMore from '@/components/landing/ReadMore.vue'
import {
  defaultTimeLine,
  prepareFadeInUpTween,
  fadeInUpTween,
} from '@/inc/utils/animations'
import gsap from 'gsap'

export default defineComponent({
  name: 'FullscreenMedia',
  props: ['content'],
  components: { ReadMore, VideoPlayer },
  methods: {
    mouseover(event) {
      window.dispatchEvent(new Event('cursorDisplay', event))
    },
    mouseleave() {
      window.dispatchEvent(new Event('cursorHide', event))
    },
    showVideo() {
      this.$refs.player.show()
    },
  },
  setup() {
    const display = ref(true)

    const preTitleRef = ref()
    const title1Ref = ref()
    const title2Ref = ref()
    const linkRef = ref()
    const visualRef = ref()
    const animationsTargets = []
    const TL = defaultTimeLine()

    onMounted(() => {
      animationsTargets.push(
        preTitleRef.value,
        title1Ref.value,
        title2Ref.value,
        linkRef.value.$el
      )
      prepareFadeInUpTween(animationsTargets)
      gsap.set(visualRef.value, {
        opacity: 0,
      })
    })

    const enterViewport = () =>
      TL.add(fadeInUpTween(animationsTargets), 'start')
        .to(
          visualRef.value,
          {
            opacity: 1,
            duration: 4,
          },
          'start'
        )
        .to(
          visualRef.value,
          {
            scale: 1.1,
            duration: 15,
          },
          'start'
        )
        .resume()

    return {
      display,
      preTitleRef,
      title1Ref,
      title2Ref,
      linkRef,
      visualRef,
      enterViewport,
    }
  },
})
</script>
