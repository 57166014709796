




























































































import { defineComponent, ref, onMounted } from '@vue/composition-api'

import VideoPlayer from '@/components/landing/VideoPlayer.vue'

import gsap from 'gsap'

const DISTANCE = 25

export default defineComponent({
  name: 'hero',
  props: ['content'],
  components: { VideoPlayer },
  methods: {
    mouseover(event) {
      window.dispatchEvent(new Event('cursorDisplay', event))
    },
    mouseleave() {
      window.dispatchEvent(new Event('cursorHide', event))
    },
    showVideo() {
      this.$refs.player.show()
    },
  },
  setup() {
    const display = ref(false)

    const logoRef = ref()
    const titleRef = ref()
    const textRef = ref()
    const infoRef = ref()
    const ctaRef = ref()
    const playRef = ref()

    const backgroundContainer = ref()
    const backgroundVisualRef = ref()

    const hide = () => {
      gsap.set(
        [
          logoRef.value,
          titleRef.value,
          textRef.value,
          infoRef.value,
          ctaRef.value,
          playRef.value,
        ],
        {
          autoAlpha: 0,
          y: DISTANCE,
          rotateZ: 0.0001, // Prevent choppy animation
        }
      )
    }

    const animateIn = () => {
      display.value = true

      const TL = gsap.timeline({
        defaults: {
          duration: 2,
          ease: 'power4.out',
        },
      })

      TL.to(backgroundContainer.value, {
        clipPath: 'inset(0% 0% 0% 0%)',
      })

      TL.to(
        [
          logoRef.value,
          titleRef.value,
          textRef.value,
          infoRef.value,
          ctaRef.value,
          playRef.value,
        ],
        {
          autoAlpha: 1,
          y: 0,
          rotateZ: 0.0001, // Prevent choppy animation
          stagger: 0.1,
        },
        '-=1'
      )
    }

    onMounted(() => {
      hide()
    })

    return {
      display,
      backgroundContainer,
      backgroundVisualRef,
      logoRef,
      titleRef,
      textRef,
      infoRef,
      ctaRef,
      animateIn,
      playRef,
    }
  },
})
