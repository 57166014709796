<template>
  <div class="share">
    <div class="share__container">
      <h2 class="share__title">Share with your community</h2>
      <socials :content="content" />
      <div class="share__footer">
        <form action="" class="share__form">
          <label for="copy" class="share__label">Copy link</label>
          <input
            type="hidden"
            name="copy"
            id="testing-code"
            :value="testingCode"
            class="share__input"
          />
          <div class="share__box">
            <span class="share__copy">{{ testingCode }}</span>
          </div>
          <button class="share__button" @click.stop.prevent="copyTestingCode">
            Copy
          </button>
        </form>
      </div>
      <button class="share__close" @click="$emit('close')">
        <span class="is-hidden">Close</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 56.7 56.7"
          class="share__svg"
        >
          <path
            fill="#a5a5a5"
            d="M51.1 8.8l-2.7-2.7-20.1 20.1L8.2 6.1 5.6 8.8l20.1 20.1-19.1 19 2.7 2.7 19-19 19.1 19 2.7-2.7-19.1-19z"
          />
        </svg>
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>

<script>
import { defineComponent } from '@vue/composition-api'
import Socials from '@/components/landing/Socials.vue'

export default defineComponent({
  name: 'Share',
  props: ['content'],
  components: { Socials },
  data() {
    return {
      testingCode: 'https://www.food4ever/xnkjf',
    }
  },
  methods: {
    copyTestingCode() {
      const testingCodeToCopy = document.querySelector('#testing-code')
      testingCodeToCopy.setAttribute('type', 'text')
      testingCodeToCopy.select()
      document.execCommand('copy')
    },
  },
})
</script>
