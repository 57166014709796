






























































import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'videoPlayer',
  props: {
    sources: Array,
    muted: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isShow: false,
      isPlaying: false,
      time: 0,
      duration: 0,
      isGrabbingSeekbar: false,
    }
  },
  mounted() {
    document.addEventListener('mousemove', event => {
      this.moveSeekbar(event)
    })
    document.addEventListener('mouseup', event => {
      this.releaseSeekbar(event)
    })
    this.$refs.video.addEventListener('loadedmetadata', () => {
      this.duration = this.$refs.video.duration
    })
    this.$refs.video.addEventListener('ended', () => {
      this.$refs.video.currentTime = 0
      this.isPlaying = false
    })
  },
  computed: {
    progress() {
      return this.time / this.duration
    },
    currentTime() {
      const minutes = Math.floor(this.time / 60)
      let seconds = Math.floor(this.time % 60)
      if (seconds < 10) {
        seconds = `0${seconds}`
      }

      return `${minutes}:${seconds}`
    },
  },
  methods: {
    show() {
      this.isShow = true
      this.play()
      document.dispatchEvent(new Event('desactivateScroll'))
      document.dispatchEvent(new Event('desactivateScrollCta'))
      this.$emit('show')
    },
    hide() {
      this.isShow = false
      this.pause()
      document.dispatchEvent(new Event('activateScroll'))
      document.dispatchEvent(new Event('activateScrollCta'))
      this.$emit('hide')
    },
    playOrPause() {
      if (this.isPlaying) {
        this.pause()
      } else {
        this.play()
      }
    },
    play() {
      this.$refs.video.play()
      this.isPlaying = true
      this.loop()
    },
    pause() {
      this.$refs.video.pause()
      this.isPlaying = false
    },
    loop() {
      this.time = this.$refs.video.currentTime
      if (!this.isPlaying) {
        return
      }
      requestAnimationFrame(() => {
        this.loop()
      })
    },
    grabSeekbar(event) {
      event.preventDefault()
      this.isGrabbingSeekbar = true
      this.$refs.video.pause()
      this.moveSeekbar(event)
    },
    moveSeekbar(event) {
      if (!this.isGrabbingSeekbar) {
        return
      }
      event.preventDefault()
      const offset = this.$refs.timeline.getBoundingClientRect().left
      const width = this.$refs.timeline.offsetWidth
      const position = event.clientX - offset - window.pageXOffset
      this.time = (position / width) * this.duration
      this.$refs.video.currentTime = this.time
    },
    releaseSeekbar(event) {
      event.preventDefault()
      this.isGrabbingSeekbar = false
      if (this.isPlaying) {
        this.$refs.video.play()
      }
    },
  },
})
