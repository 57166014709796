<template>
  <div
    class="testimonial"
    data-parallax
    v-enter-viewport
    @enterViewport="enterViewport"
  >
  <div class="testimonial__illustration" aria-hidden="true" ref="illuRef"></div>
    <h2 class="is-hidden">{{ content.title }}</h2>
    <blockquote
      class="testimonial__content-container"
      v-if="content.picture || content.text"
    >
      <ParallaxVisual
        v-if="content.picture"
        ref="visualRef"
        name="testimonial__visual"
        :src="content.picture.src"
        :alt="content.picture.alt"
      />
      <p class="testimonial__content" v-if="content.text" ref="contentRef">
        {{ content.text }}
      </p>
    </blockquote>
    <div v-if="content.author">
      <div class="testimonial__author" ref="authorNameRef">
        {{ content.author.name }}
      </div>
      <div class="testimonial__author-role" ref="authorRoleRef">
        {{ content.author.role }}
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>

<script>
import { defineComponent, onMounted, ref } from '@vue/composition-api'

import {
  defaultTimeLine,
  prepareFadeInUpTween,
  fadeInUpTween,
} from '@/inc/utils/animations'
import ParallaxVisual from '@/components/landing/ParallaxVisual.vue'

export default defineComponent({
  name: 'Testimonial',
  props: ['content'],
  components: { ParallaxVisual },
  setup() {
    const contentRef = ref()
    const authorNameRef = ref()
    const authorRoleRef = ref()
    const illuRef = ref()
    const visualRef = ref()

    const animationsTargets = []
    const TL = defaultTimeLine()

    onMounted(() => {
      animationsTargets.push(
        contentRef.value,
        authorNameRef.value,
        authorRoleRef.value,
        illuRef.value
      )
      prepareFadeInUpTween(animationsTargets)
    })

    const enterViewport = () =>
      TL.add(fadeInUpTween(animationsTargets), 'start')
        .add(visualRef.value.animateIn, '<.35')
        .resume()

    return {
      enterViewport,
      contentRef,
      authorNameRef,
      authorRoleRef,
      illuRef,
      visualRef,
    }
  },
})
</script>
