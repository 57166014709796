<template>
  <div class="progressBar" aria-hidden="true">
    <div class="progressBar__colors"></div>
    <div class="progressBar__white" :style="style"></div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'ProgressBar',
  props: ['content'],
  mounted() {
    window.addEventListener('scroll', () => this.handleScroll())
  },
  data() {
    return {
      translate: 0,
    }
  },
  methods: {
    handleScroll() {
      const winScroll =
        document.body.scrollTop || document.documentElement.scrollTop
      const height =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight
      this.translate = (winScroll / height) * 100
    },
  },
  computed: {
    style() {
      return `transform:translateX(${this.translate}%)`
    },
  },
})
</script>
